@import '@assets/scss/v2/main';

.container {
  width: 100%;
  height: 100%;
}

.button {
  margin: 0 auto;
  width: 64%;
}

.passwordGroup {
  margin-top: 3rem;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  @include respond(l) {
    padding: 7rem;
    margin-top: 8rem;
  }
}

.passwordInput {
  margin: 0 auto;
  width: 64%;
  height: 5rem;
  @include textBold;
  margin-bottom: 3rem;
  padding-left: 1rem;
}

.headerContainer {
  padding: 1.5rem;
  height: 100%;
}

.label {
  margin-bottom: 1rem;
}

.formContainer {
  display: flex;
  flex-direction: column;
}
